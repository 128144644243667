import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import ThirdSection from "./components/ThirdSection";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import {BrowserRouter as Router, Route, Routes, Switch} from 'react-router-dom';
import TermsAndConditions from "./components/TermsAndConditions";
import AuthModalTerms from "./components/AuthModalTerms";
import AuthModalFaq from "./components/AuthModalFaq";

function App() {

    return (
    <div className="bg-gradient-to-r from-blue-950 to-gray-900  w-full text-white ">
        <Navbar/>
        <HeroSection/>
        <ThirdSection />
        <Section4/>
        <Section5/>
        <Faq/>
        <Footer/>

            <Routes>
            <Route path="/faq" element={<AuthModalFaq/>} />
            <Route path="/terms" element={<AuthModalTerms/>} />
            </Routes>

    </div>
  );
}

export default App;
